import React, { PureComponent } from 'react';
import { bool, func, node, number, oneOf, string } from 'prop-types';

import cx from './GhostButton.sss';

import Icon from '../../../components/Icon';

export default class GhostButton extends PureComponent {
    static propTypes = {
        children: node,
        color: string,
        component: string,
        direcion: oneOf(['column']),
        disabled: bool,
        icon: string,
        iconColor: string,
        iconSize: oneOf(['big', 'small']),
        iconStyle: oneOf(['inline']),
        onClick: func,
        size: number,
        type: oneOf(['submit', 'button']),
    };

    static defaultProps = {
        component: 'button',
        onClick: () => {},
        type: 'button',
    };

    render() {
        const {
            children,
            color,
            direcion,
            component: Component,
            icon,
            iconColor,
            size,
            iconSize,
            iconStyle,
            ...props
        } = this.props;

        return (
            <Component
                className={cx('GhostButton', {
                    [`GhostButton_direcion_${direcion}`]: direcion,
                    [`GhostButton_color_${color}`]: color,
                    ['GhostButton_disabled']: props.disabled,
                })}
                {...props}
            >
                <span className={cx('inner')}>
                    {icon && (
                        <span
                            className={cx('icon', {
                                [`icon_color_${iconColor}`]: iconColor,
                                [`icon_size_${iconSize}`]: iconSize,
                                [`icon_style_${iconStyle}`]: iconStyle,
                            })}
                        >
                            <Icon height={size} icon={icon} width={size} />
                        </span>
                    )}
                    {children && <span className={cx('text')}>{children}</span>}
                </span>
            </Component>
        );
    }
}
