import React from 'react';
import { bool } from 'prop-types';
import { Dictionary } from 'shared';

import cx from './FailedToLoad.sss';

import GhostButton from '../GhostButton';

const reloadPage = () => {
    document.location.reload();
};

const FailedToLoad = ({ short, transparentBackground }) => {
    return (
        <div className={cx('FailedToLoad', { transparentBackground })}>
            {short ? (
                <div className={cx('top')}>
                    <Dictionary text="com.error.loading.title" />
                </div>
            ) : (
                <>
                    <div className={cx('top')}>
                        <Dictionary text="com.error.loading.title" />
                        <br />
                        <Dictionary text="com.error.loading.description" />
                    </div>

                    <div className={cx('button')}>
                        <GhostButton
                            color="white"
                            direcion="column"
                            icon="refresh"
                            onClick={reloadPage}
                        >
                            <Dictionary text="com.error.loading.refresh" />
                        </GhostButton>
                    </div>
                </>
            )}
        </div>
    );
};

FailedToLoad.propTypes = {
    short: bool,
    transparentBackground: bool,
};

export default FailedToLoad;
