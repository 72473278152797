import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bool, func, node, oneOfType } from 'prop-types';

export default function Load(config = {}) {
    return WrappedComponent => {
        const mapStateToProps = (state, props) => ({
            isError: config.isError(state),
            isLoading: config.isLoading(state, props),
            Loader:
                typeof config.Loader === 'function'
                    ? config.Loader(state, props)
                    : config.Loader,
            Rebooter:
                typeof config.Rebooter === 'function'
                    ? config.Rebooter(state, props)
                    : config.Rebooter,
        });

        class Loadable extends PureComponent {
            displayName = `Loadable(${WrappedComponent.name})`;

            static propTypes = {
                dispatch: func.isRequired,
                isError: bool,
                isLoading: bool,
                Loader: node,
                onReboot: func,
                Rebooter: oneOfType([func, node]),
            };

            static defaultProps = {
                isError: false,
                isLoading: false,
                onReboot: () => {},
                Rebooter: null,
            };

            render() {
                const {
                    Loader,
                    Rebooter,
                    isLoading,
                    isError,
                    onReboot,
                    ...props
                } = this.props;

                if (isError && Rebooter) {
                    return Rebooter;
                }

                if (isError || isLoading) {
                    return Loader;
                }

                return <WrappedComponent {...props} />;
            }
        }

        return connect(mapStateToProps)(Loadable);
    };
}
